/* visionSection.css */

/* 全体のスタイル */
.vision-section {
	padding: 60px 20px;
	background-color: #f9f9f9;
}

.vision-section p {
	font-size: 1.2rem;
	font-weight: 800;
	padding-top: 10px;
	text-align: center;
}

.vision-title {
	text-align: center;
	font-size: 3.0rem;
	color: #0e388c;
	padding-top: 40px;
	font-weight: 800;
}

.main-vision {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.vision-block {
	width: 100%;
	max-width: 90%;
	margin: 40px 0;
	padding: 20px;
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
	text-align: center;
	display: flex;
    transition: transform 0.5s ease-in-out;
    overflow-x: scroll;
    scrollbar-width: none; /* Firefox用 */
    -ms-overflow-style: none; /* IE用 */
    gap: 20px; /* 左右の要素間のスペース */
}

.content-left,
.content-right {
    flex: 1; /* 均等に幅を配分 */
}

.content-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* 左寄せ */
    margin: 15px;
}

.content-right {
    display: flex;               /* Flexbox を使用 */
    flex-direction: column;      /* 縦方向に並べる */
    justify-content: center;     /* 垂直方向に中央揃え */
    text-align: left;
    height: 100%;
}

.sub-block-container {
    display: flex;
    transition: transform 0.5s ease-in-out;
    overflow-x: scroll;
    scrollbar-width: none; /* Firefox用 */
    -ms-overflow-style: none; /* IE用 */
    gap: 20px; /* 左右の要素間のスペース */
}

.vision-block h3 {
	font-size: 2.5rem;
	font-weight: 700;
	padding-bottom: 10px;
	color: #333;
}

.sub-image {
	margin: 20px 0;
}

.sub-image img {
	width: 100%;
	max-width: 400px;
	height: auto;
	border-radius: 10px;
}

.vision-block p {
	font-size: 1.1rem;
	font-weight: 600;
	color: #333;
	margin-bottom: 20px;
}

.vision-block ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.vision-block ul li {
	font-size: 1rem;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.vision-block ul li i {
	margin-right: 8px;
	color: #0e388c;
}

/* レスポンシブ対応 */
@media (max-width: 768px) {
	.vision-title {
		font-size: 2.5rem;
		padding-top: 30px;
	}

	.vision-block h3 {
		font-size: 2rem;
	}

	.vision-block p {
		font-size: 1rem;
	}
}

@media (max-width: 480px) {
	.vision-section {
		padding: 40px 10px;
	}

	.vision-title {
		font-size: 2.2rem;
		padding-top: 20px;
	}

	.vision-block h3 {
		font-size: 1.8rem;
	}

	.vision-block p {
		font-size: 0.9rem;
	}

	.vision-block ul li {
		font-size: 0.9rem;
	}
}