/* FindWorkSection.css */

.tuition-support-section {
    padding: 60px 20px;
}

.tuition-support-section p {
    font-size: 1.2rem;
    font-weight: 800;
    padding-top: 10px;
}

.tuition-support-title {
    text-align: center;
    font-size: 3.0rem;
    color: #0e388c;
    padding-top: 40px;
    font-weight: 800;
    margin-bottom: 0;
}

/* カードのコンテナ */
.tuition-support-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    margin: 40px auto;
    width: 100%;
}

/* 各カードのスタイル */
.tuition-support-card {
    background-color: #f9f9f9;
    width: 30%;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
}

.tuition-support-card img {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: 10px;
}

.tuition-support-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

/* カード内のテキスト */
.tuition-support-card h3 {
    font-size: 1.8rem;
    font-weight: bold;
    color: #1b479c;
    margin-top: 20px;
}

.tuition-support-card p {
    font-size: 1.2rem;
    color: #555;
    margin: 0;
}

/* 矢印 */
.tuition-support-card .arrow {
    position: absolute;
    bottom: 10px;
    right: 20px;
    font-size: 2rem;
    color: #1b479c;
}

.tuition-support-card button {
    padding: 15px;
    font-size: 0.9rem;
    font-weight: bold;
    margin: 20px auto 10px auto;
    width: 40%;
    height: auto;
    color: #333;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    min-width: 240px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.tuition-support-card button::after {
    content: "\f138";
    font-family: "Font Awesome 6 Free"; /* フォントファミリーを指定 */
    font-weight: 900; /* Solid アイコンの場合、font-weight を 900 に設定 */
    color: #1b479c;
    font-size: 1.5rem;
    margin-left: 20px; /* テキストとの余白 */
    transition: color 0.3s ease; /* Smooth transition for color change */
}

@media (max-width: 1240px) {
    .tuition-support-cards {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        gap: 30px;
        margin: 40px auto;
        width: 100%;
    }

    .tuition-support-card {
        width: 45%;
    }
}

@media (max-width: 768px) {
    .tuition-support-card {
        width: 90%;
    }

    .tuition-support-cards {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        gap: 30px;
        margin: 40px auto;
        width: 100%;
    }
}

@media (max-width: 480px) {
    .tuition-support-section {
        padding: 40px 10px;
    }

    .tuition-support-title {
        font-size: 2.3rem;
        padding-top: 20px;
        text-align: left;
        padding-left: 20px;
    }

    .tuition-support-description {
        font-size: 1.0rem;
        text-align: left;
        padding-left: 20px;
    }

    .tuition-support-cards {
        flex-direction: column;
        gap: 20px;
        width: 100%;
        margin: 20px auto;
    }

    .tuition-support-card {
        width: 100%;
        padding: 15px;
    }

    .tuition-support-card h3 {
        font-size: 1.5rem;
    }

    .tuition-support-card p {
        font-size: 1rem;
    }

    .tuition-support-card .arrow {
        font-size: 1.5rem;
        bottom: 5px;
        right: 10px;
    }
}