/* ProcedureSection.css */

.procedure-section {
    padding: 40px;
    text-align: center;
    /* background-color: #f9f9f9; */
}

.procedure-section p {
    font-size: 1.2rem;
    font-weight: 800;
    padding-top: 10px;
}

.procedure-title {
    text-align: center;
    font-size: 3.0rem;
    color: #0e388c;
    padding-top: 40px;
    font-weight: 800;
}

.procedure-container {
    position: relative;
    width: 100%;
    margin-top: 40px;
}

.procedure-slider {
    display: flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* IE 10+ */
    gap: 20px;
    padding: 20px;
}

.procedure-slider::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari */
}

.procedure-item {
    flex: 0 0 500px;
    /* アイテムの幅を固定 */
    text-align: left;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease;
    background-color: #f9f9f9;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.procedure-item:hover {
    transform: scale(1.03);
}

.procedure-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 20px;
}

.procedure-item h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.procedure-item p {
    font-size: 1em;
    color: #666;
}

.fa-circle {
    scale: 0.6;
    color: #1b479c;
    margin-right: 10px;
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #f9f9f9;
    width: 40px;
    height: 40px;
    background-color: #1b479c;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    z-index: 1;
}

.left-arrow {
    left: 10px;
}

.right-arrow {
    right: 10px;
}

.dots-container {
    text-align: center;
    margin-top: 20px;
}

.dot {
    display: inline-block;
    width: 12px;
    height: 12px;
    background-color: #ccc;
    border-radius: 50%;
    margin: 5px;
    cursor: pointer;
}

.dot.active {
    background-color: #0e388c;
}

/* レスポンシブ対応 */
@media (max-width: 768px) {
    .procedure-item {
        flex: 0 0 80%;
    }

    .procedure-image {
        width: 100%;
        height: auto;
    }

}

@media (max-width: 480px) {
    .procedure-section {
        padding: 15px;
    }

    .procedure-title {
        font-size: 2.5rem;
        padding-top: 15px;
        text-align: left;
        padding-left: 15px;
    }

    .procedure-section .procedure-description {
        font-size: 1.1rem;
        text-align: left;
        padding-left: 15px;
    }

    .procedure-item {
        flex: 0 0 100%;
        padding: 15px;
    }

    .procedure-image {
        width: 100%;
        height: auto;
    }

    .procedure-item h3 {
        font-size: 1.2em;
    }

    .procedure-item p {
        font-size: 0.9em;
    }

    .arrow {
        width: 30px;
        height: 30px;
    }
}