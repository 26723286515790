/* FindWorkSection.css */

.find-work-section {
    padding: 60px 20px;
}

.find-work-section p {
    font-size: 1.2rem;
    font-weight: 800;
    padding-top: 10px;
}

.find-work-title {
    text-align: center;
    font-size: 3.0rem;
    color: #0e388c;
    padding-top: 40px;
    font-weight: 800;
    margin-bottom: 0;
}

/* カードのコンテナ */
.find-work-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin: 40px auto;
    width: 100%;
}

/* 各カードのスタイル */
.find-work-card {
    background-color: white;
    width: 30%;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
}

.find-work-card img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 10px;
}

.find-work-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

/* カード内のテキスト */
.find-work-card h3 {
    font-size: 1.8rem;
    font-weight: bold;
    color: #1b479c;
    margin-top: 20px;
}

.find-work-card p {
    font-size: 1.2rem;
    color: #555;
    margin: 10px 0;
}

/* 横スクロールのロゴコンテナ */
.find-work-logos {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    width: 100%;

    padding: 20px 0;
}

.partner-title {
    font-size: 1.2rem;
    font-weight: 800;
    margin-top: 50px;
}

/* ロゴをスライドさせるラッパー */
.logos-wrapper {
    display: flex;
    gap: 40px;
    animation: scrollLogos 15s linear infinite;
}

/* ロゴのスタイル */
.partner-logo {
    height: 60px;
    width: auto;
    display: inline-block;
}

/* 横スクロールアニメーション */
@keyframes scrollLogos {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-50%);
    }
}

@media (max-width: 1240px) {
    .find-work-cards {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        gap: 30px;
        margin: 40px auto;
        width: 100%;
    }

    .find-work-card {
        width: 45%;
    }

    .find-work-card img {
        height: 300px;
    }
}

@media (max-width: 768px) {
    .find-work-card {
        width: 45%;
    }

    .find-work-cards {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        gap: 30px;
        margin: 40px auto;
        width: 100%;
    }

    .find-work-card img {
        height: 200px;
    }
}

@media (max-width: 480px) {
    .find-work-section {
        padding: 40px 10px;
    }

    .find-work-title {
        font-size: 2.5rem;
        padding-top: 20px;
        text-align: left;
        padding-left: 20px;
    }

    .find-work-description {
        font-size: 1.0rem;
        text-align: left;
        padding-left: 20px;
    }

    .find-work-cards {
        flex-direction: column;
        gap: 20px;
        width: 100%;
        margin: 20px auto;
    }

    .find-work-card {
        width: 100%;
        padding: 15px;
    }

    .find-work-card h3 {
        font-size: 1.5rem;
    }

    .find-work-card p {
        font-size: 1rem;
    }

    .find-work-card .arrow {
        font-size: 1.5rem;
        bottom: 5px;
        right: 10px;
    }

    @keyframes scrollLogos {
        from {
            transform: translateX(0);
        }
        to {
            transform: translateX(-200%);
        }
    }
}