.admission-button-section {
    padding: 150px 20px;
    text-align: center;
    background-color: #f9f9f9;
}

.admission-button-title {
    text-align: center;
    font-size: 3.0rem;
    color: #0e388c;
    padding-top: 40px;
    font-weight: 800;
}

.admission-button-section p {
    font-size: 1.2rem;
    font-weight: 800;
    padding-top: 10px;
}

.admission-button-container {
    display: flex;
    justify-content: center;
    position: relative;
    margin: 40px;
}

.contact-button {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    text-decoration: none;
    /* Button width */
    height: 100px;
    background-color: #ffffff;
    /* Button height */
    /* Light background */
    border: 2px solid #1b479c;
    /* Light purple border */
    border-radius: 20px;
    /* Rounded corners */
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Optional shadow */
    transition: background-color 0.3s ease, color 0.3s ease;
}

.contact-button:hover {
    background-color: #1b479c;
    color: #ffffff;
    /* Slight zoom effect on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    /* Stronger shadow on hover */
}

/* Ensure text color inside button changes on hover */
.contact-button:hover .small-text,
.contact-button:hover .large-text {
    color: #ffffff; /* Invert to white */
}

.small-text {
    font-size: 0.9rem;
    color: #1b479c;
    /* Light purple text for the small line */
    margin-bottom: 5px;
}

.large-text {
    font-size: 1.5rem;
    color: #1b479c;
    /* Dark purple text */
}

.contact-button h2::after {
    content: "\f138";
    font-family: "Font Awesome 6 Free"; /* フォントファミリーを指定 */
    font-weight: 900; /* Solid アイコンの場合、font-weight を 900 に設定 */
    color: #1b479c;
    font-size: 2rem;
    margin-left: 30px;
    vertical-align: middle;
    transition: color 0.3s ease; /* Smooth transition for color change */
}

/* Hover effect for ::after icon */
.contact-button:hover h2::after {
    color: #ffffff; /* Invert icon color on hover */
}

@media (max-width: 768px) {
    .admission-button-section {
        padding: 150px 20px;
    }

    .admission-button-container {
        gap: 15px;
        justify-content: center;
        flex-direction: row;
    }

    .contact-button {
        height: auto;
        margin: 10px;
    }

    .large-text {
        font-size: 1.5rem;
        padding: 30px;
    }

    .contact-button h2::after {
        font-size: 2rem;
        margin-left: 15px;
    }
}

@media (max-width: 480px) {
    .admission-button-section {
        padding: 100px 20px;
    }

    .admission-button-section .admission-button-description {
        font-size: 1.0rem;
    }

    .admission-button-container {
        flex-direction: column;
        gap: 15px;
    }

    .contact-button {
        width: 100%;
        height: auto;
        margin: 10px;
    }

    .large-text {
        font-size: 1.5rem;
        padding: 30px;
    }

    .contact-button h2::after {
        font-size: 2rem;
        margin-left: 15px;
    }
}